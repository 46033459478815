<template>
  <div>
    <headerImg :src="img" height="640" />
    <div class="content">
      <div class="content-title">下载中心</div>
      <hr />
      <div class="content-subTitle">我们提供了产品说明书、彩页、软件等资料下载，您可在右边输入关键字进行快捷查询</div>
      <div class="search-box">
        <input type="text" v-model="keyword" @input="changeKeyWord" placeholder="请输入关键字查询">
        <div>搜索</div>
      </div>
      <div class="file-list">
        <div>
          <div>名称</div>
          <div>下载</div>
        </div>
        <template v-if="fileList.length">
          <div v-for="(item, index) in fileList" :key="index">
            <a :title="item.name" :href="item.url"><i class="iconfont duozhi-icon-jia"></i><span v-html="item.name"></span></a>
            <a :title="item.name" :href="item.url" class="iconfont duozhi-icon-xiazai"></a>
          </div>
        </template>
        <div v-else>
          <a><i class="iconfont duozhi-icon-jia"></i>无数据</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import img from "@/assets/images/customerService/haederimg.png"
import headerImg from "@/components/headerImg.vue"
const fileList = [
  {name: "U939系列操作说明书", url: "/file/U939系列操作说明书.pdf"}
]
export default {
  metaInfo: {
    title: "深圳多知能源物联有限公司专注智能家居,烟雾报警器,燃气报警器",
    meta: [
      {
        name: "keywords",
        content: "燃气报警器电子产业互联网,液化石油气LPG,物联网应用软硬件产品,燃企配送移动端",
      },
      {
        name: "description",
        content:
          "优气物联致力于“液化石油气LPG” 物联网应用软硬件产品的研发、生产、销售和服务。旨在为燃气企业赋能，降低成本，提升效率，为监管单位提供高效精准的监管渠道，为终端用户带来高质量的用气体验。打造一个聚焦LPG领域，融合新零售、采用大数据、物联网等前沿技术的一站式服务平台。",
      },
    ],
  },
  components: {headerImg},
  data(){
    return {
      img,
      keyword: "",
      fileList: JSON.parse(JSON.stringify(fileList))
    }
  },
  methods: {
    changeKeyWord(){
      const keyword = this.keyword.toUpperCase()
      this.fileList = fileList.map(item => ({name: item.name.indexOf(keyword) != -1 ? item.name.replace(keyword, `<span style="color: red;font-weight: 600;">${keyword}</span>`): item.name, url: item.url}))
    }
  }

}
</script>

<style lang="scss" scoped>
.content{
  width: 1200px;
  margin: 0 auto;
  text-align: left;
  .content-title{
    line-height: 31px;
    font-size: 21px;
    color: #333333;
    margin: 40px 0 10px 0 ;
  }
  .content-subTitle{
    color: #b6b9ba;
    font-size: 18px;
    line-height: 50px;
  }
  .search-box{
    border-radius: 50px;
    overflow: hidden;
    border: 1px solid #ccc;
    width: fit-content;
    margin-bottom: 20px;
    display: flex;
    input{
      outline: none;
      border: 0px;
      padding: 0 10px;
      width: 150px;
    }
    div{
      padding: 0 10px;
      font-size: 14px;
      background: #ccc;
    }
  }
  .file-list{
    & > div:first-child{
      background: #cccccc;
      div{
        font-size: 14px;
        color: white;
      }
    }
    & > div{
      display: flex;
      justify-content: space-between;
      line-height: 50px;
      background: #eeeeee;
      margin: 5px 0;
      padding: 0 15px;
      .iconfont{
        font-size: 20px;
        color: #f7af41;
      }
      a{
        font-size: 14px;
        color: #535353;
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .content{
    max-width: 98%!important;
    .content-subTitle{
      font-size: 14px;
      line-height: 24px;
    }
  }
}

</style>